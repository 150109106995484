import { Link as GatsbyLink } from "gatsby-plugin-react-i18next";
import {
  LeadSection,
  Post,
  Link,
  FormInput,
  FormCheckbox,
  Card,
  PostContentsContainer,
  Button,
} from "@lleed-partners/factor";
import { ArrowRight32 } from "@carbon/icons-react";
import { graphql } from "gatsby";
import { useI18next, Trans } from "gatsby-plugin-react-i18next";
import { Icon } from "../../fragments/Icon";
import { Layout } from "../../layouts";
import { Helmet } from "gatsby-plugin-react-i18next";

export default () => {
  const { t, language } = useI18next();

  return (
    <Layout
      navProps={{
        showSubNav: false,
      }}
    >
      <Helmet>
        <meta
          name="description"
          content={t("lleedpartners.contact-description-support")}
        />
        <title>{t("Support request")}</title>
      </Helmet>
      <LeadSection
        intent="grey"
        padTop
        inline
        title={t("Support request")}
        subtitle={t("You are seconds away from getting help.")}
        cta={
          <>
            <Link
              intent="ghost"
              as={GatsbyLink}
              to="/contact/"
              icon={<Icon icon={<ArrowRight32 />} />}
            >
              {t("Other contact options")}
            </Link>
          </>
        }
      />
      <form
        name="contact-support"
        method="post"
        data-netlify="true"
        netlify-honeypot="bot-field"
        action={`/${language}/contact/thank-you`}
      >
        <div style={{ display: "none" }}>
          <input type="hidden" name="form-name" value="contact-support" />
          <input name="bot-field" />
        </div>
        <Post>
          <h2>{t("About you")}</h2>
          <p>
            <Trans t={t}>
              Please give us more information about the impacted project.
            </Trans>
          </p>
          <PostContentsContainer position="left">
            <Card intent="grey">
              <Trans t={t}>
                Please provide either a contract number or company name and
                contact information. If you provide us with a contract number,
                we will use the contact information on the contract or our most
                recent point of contact for this project.
              </Trans>
            </Card>
          </PostContentsContainer>
          <div>
            <FormInput
              label={t("Contract number")}
              placeholder={123456}
              name="contract-number"
            />
            <FormInput
              label={t("Company name")}
              placeholder={t("ACME")}
              name="company-name"
            />
            <FormInput
              label={t("Contact name")}
              placeholder={t("John Doe")}
              name="contact-name"
            />
            <FormInput
              label={t("Email")}
              placeholder={t("john.doe@acme.com")}
              name="contact-email"
            />
            <FormInput
              label={t("Phone")}
              placeholder="+1 (716) 712-4110"
              name="contact-phone"
            />
            <FormCheckbox
              label={t("Contact preferences")}
              name="contact-prefers-email"
              defaultChecked="on"
            >
              {t("I prefer to be contacted by email")}
            </FormCheckbox>
            <FormCheckbox name="contact-prefers-phone">
              {t("I prefer to be contacted by phone")}
            </FormCheckbox>
          </div>
          <h2>{t("Issue")}</h2>
          <p>
            <Trans t={t}>
              Let us know more about the issue you are encountering.
            </Trans>
          </p>
          <PostContentsContainer position="center">
            <FormInput
              as="textarea"
              label={t("Information about the issue")}
              name="message"
              required
              defaultValue={t("Tell us your issue...")}
              style={{ minHeight: "50vh" }}
            />
          </PostContentsContainer>
          <h2>{t("Consent options")}</h2>
          <FormCheckbox
            name="contact-consents"
            required
            label="Automated data treatment"
          >
            <Trans t={t}>
              I hereby allow Lleed & Partners to process the data in this form.
              <br />
              <small>
                Lleed & Partners will not distribute your data to third parties.
                Lleed & Partners will not use your data for marketing purposes.
              </small>
            </Trans>
          </FormCheckbox>
          <p />
          <Button
            as="button"
            type="submit"
            size="large"
            rightIcon={<Icon icon={<ArrowRight32 />} />}
          >
            {t("Submit this form")}
          </Button>
          <p />
        </Post>
      </form>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
